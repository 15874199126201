import Vue from 'vue';
import Router from 'vue-router';

import Dashboard from '@/views/Dashboard';
import DashboardProvisioning from '@/views/DashboardProvisioning';
import DashboardWithdrawal from '@/views/DashboardWithdrawal';
import DashboardOrderSummary from '@/views/DashboardOrderSummary.vue';
import Tasks from '@/views/Tasks';
import PhoneNumberPorting from '@/views/PhoneNumberPorting';
import Provisioning from '@/views/Provisioning';
import TvProvisioning from '@/views/TvProvisioning';
import TvProvisioningDashboard from '@/views/TvProvisioningDashboard';
import ServiceOrder from '@/views/ServiceOrder.vue';
import Statistic from '@/views/Statistic';
import StatistikDashboard from '@/views/StatistikDashboard.vue';
import Withdrawal from '@/views/Withdrawal.vue';
import SelocaWrapper from '@/views/SelocaWrapper';
import SelocaArticleList from '@/views/SelocaArticleList.vue';
import SelocaOrderHistory from '@/views/SelocaOrderHistory';
import MSANConfig from '@/views/MSANConfig.vue';
import GncConstructionList from '@/views/GncConstructionList';
import SupportStart from '@/views/SupportStart.vue';
import ConstructionProjects from '@/views/ConstructionProjects';
import ConstructionProjectOverview from '@/views/ConstructionProjects/Overview';
import ConstructionProjectOverviewMap from '@/views/ConstructionProjects/OverviewMap';
import NewProject from '@/views/ConstructionProjects/NewProject';
import ConstructionProjectStates from '@/views//ConstructionProjectStates';
import RoutesPermissionOverview from '@/views/RoutesPermissionOverview';
import HomePassedUnblocking from '@/views/HomePassedUnblocking';
import TwoFactorAdmin from '@/views/TwoFactorAdmin';
import Addressmanagement from '@/views/Addressmanagement';
import RadiusSessionLock from '@/views/RadiusSessionLock';
import SavedForSecondConstructionPhaseUnblocking from '@/views/SavedForSecondConstructionPhaseUnblocking';
import OnlineConstructionList from '@/views/OnlineConstructionList';
// import TariffManagement from '@/views/ConstructionProjects/TariffManagement';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Aufgabenliste',
    component: Tasks,
    meta: {
      visible: true
    },
    icon: 'mdi-clipboard-list-outline'
  },
  {
    path: '/porting-dasboard',
    name: 'Portierung',
    component: Dashboard,
    icon: 'mdi-format-list-bulleted-type',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotPortierung', 'LgSpotPortierungLeitung']
    }
  },
  {
    path: '/porting',
    name: 'Start Portierung',
    component: PhoneNumberPorting,
    icon: 'mdi-phone-forward-outline',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotPortierung', 'LgSpotPortierungLeitung']
    }
  },
  {
    path: '/provisioning-dashboard',
    name: 'Provisionierung',
    component: DashboardProvisioning,
    icon: 'mdi-format-list-bulleted-type',
    meta: {
      visible: true,
      auth: [
        'camunda-admin',
        'LgSpotProvisionierung',
        'LgSpotProvisionierungLeitung',
        'LgSpotSecondLevel',
        'LgSpotSecondLevelLeitung'
      ]
    }
  },
  {
    path: '/provisioning',
    name: 'Start Provisionierung',
    component: Provisioning,
    icon: 'mdi-lan-pending',
    meta: {
      visible: true,
      auth: [
        'camunda-admin',
        'LgSpotProvisionierung',
        'LgSpotProvisionierungLeitung',
        'LgSpotAccess',
        'LgSpotSecondLevel',
        'LgSpotSecondLevelLeitung'
      ]
    }
  },
  {
    path: '/tv-provisioning',
    name: 'Start TV Provisionierung',
    component: TvProvisioning,
    icon: 'mdi-television-classic',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAuftragserfassung']
    }
  },
  {
    path: '/tv-provisioning-dashboard',
    name: 'TV Provisionierungen',
    component: TvProvisioningDashboard,
    icon: 'mdi-format-list-bulleted-type',
    meta: {
      visible: true,
      auth: ['camunda-admin']
    }
  },
  {
    path: '/msanConfig',
    name: 'MSAN Konfiguration',
    component: MSANConfig,
    icon: 'mdi-server-network',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAccess', 'LgSpotSecondLevel']
    }
  },
  {
    path: '/order-dashboard',
    name: 'Auftragseingänge',
    component: DashboardOrderSummary,
    icon: 'mdi-format-list-bulleted-type',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAuftragserfassung', 'LgSpotAgentur']
    }
  },
  {
    path: '/order',
    name: 'Auftragserfassung',
    component: ServiceOrder,
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAuftragserfassung']
    },
    icon: 'mdi-file-edit-outline'
  },
  {
    path: '/withdrawal',
    name: 'Start Kündigung & Widerruf',
    component: Withdrawal,
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAuftragserfassung']
    },
    icon: 'mdi-file-cancel-outline'
  },
  {
    path: '/withdrawal-dashboard',
    name: 'Kündigung & Widerruf',
    component: DashboardWithdrawal,
    icon: 'mdi-format-list-bulleted-type',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAuftragserfassung']
    }
  },
  {
    path: '/seloca',
    name: 'Seloca',
    component: SelocaWrapper,
    meta: {
      visible: true,
      auth: [
        'camunda-admin',
        'LgSpotAuftragsmanagementLeitung',
        'LgSpotAuftragsmanagement',
        'LgSpotFieldservice'
      ]
    },
    icon: 'mdi-package-variant',
    children: [
      {
        path: 'articles',
        name: 'Seloca Artikelliste',
        component: SelocaArticleList,
        meta: {
          visible: true,
          auth: ['camunda-admin', 'LgSpotAuftragsmanagementLeitung']
        },
        icon: 'mdi-package-variant'
      },
      {
        path: 'history',
        name: 'Versandhistorie',
        component: SelocaOrderHistory,
        meta: {
          visible: true,
          auth: [
            'camunda-admin',
            'LgSpotAuftragsmanagementLeitung',
            'LgSpotAuftragsmanagement',
            'LgSpotFieldservice'
          ]
        },
        icon: 'mdi-history'
      }
    ]
  },
  {
    path: '/gnc-construction-list',
    name: 'GNC Bauliste',
    component: GncConstructionList,
    icon: 'mdi-account-hard-hat',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotBau', 'LgSpotBauLeitung', 'LgSpotGnc']
    }
  },
  {
    path: '/online-construction-list',
    name: 'Kundenbauliste',
    component: OnlineConstructionList,
    icon: 'mdi-web-sync',
    meta: {
      visible: true,
      auth: [
        'camunda-admin',
        'LgSpotKblTest',
        'LgSpotNU',
        'LgSpotAccess',
        'LgSpotBau',
        'LgSpotBauLeitung',
        'LgSpotFieldservice'
      ]
    }
  },
  {
    path: '/construction-projects',
    name: 'Projekte',
    component: ConstructionProjects,
    icon: 'mdi-bulldozer',
    meta: {
      visible: true,
      auth: [
        'camunda-admin',
        'LgSpotProjekte',
        'LgSpotVertrieb',
        'LgSpotControlling',
        'LgSpotAccess',
        'LgSpotFieldservice',
        'LgSpotBau',
        'LgSpotBauLeitung',
        'LgSpotRegionalLeitung',
        'LgSpotAddressmaster',
        'LgSpotSecondLevel'
      ]
    },
    children: [
      {
        path: 'statistic',
        name: 'Statistik',
        component: Statistic,
        icon: 'mdi-chart-multiple',
        meta: {
          visible: true,
          auth: [
            'camunda-admin',
            'LgSpotProjekte',
            'LgSpotVertrieb',
            'LgSpotControlling',
            'LgSpotSecondLevel'
          ]
        }
      },
      {
        path: 'project-list',
        name: 'Übersicht',
        component: ConstructionProjectOverview,
        icon: 'mdi-format-list-numbered',
        meta: {
          visible: true,
          auth: [
            'camunda-admin',
            'LgSpotIntern',
            'LgSpotProjekte',
            'LgSpotVertrieb',
            'LgSpotControlling',
            'LgSpotRegionalLeitung',
            'LgSpotSecondLevel'
          ]
        }
      },
      {
        path: 'address-management',
        name: 'Adressverwaltung',
        component: Addressmanagement,
        icon: 'mdi-map',
        meta: {
          visible: true,
          auth: [
            'camunda-admin',
            'LgSpotAddressmaster',
            'LgSpotVertrieb',
            'LgSpotProjekte',
            'LgSpotAccess',
            'LgSpotBau',
            'LgSpotBauLeitung',
            'LgSpotSecondLevel'
          ]
        }
      },
      // {
      //   path: 'tariff-management',
      //   name: 'Tarifverwaltung',
      //   component: TariffManagement,
      //   icon: 'mdi-tag',
      //   meta: {
      //     visible: true,
      //     auth: [
      //       'camunda-admin',
      //       'LgSpotProjekte',
      //       'LgSpotVertrieb',
      //       'LgSpotControlling'
      //     ]
      //   }
      // },
      {
        path: 'new-project',
        name: 'Neues Projekt',
        component: NewProject,
        icon: 'mdi-layers-plus',
        meta: {
          visible: true,
          auth: ['camunda-admin']
        }
      },
      {
        path: 'project-state-change',
        name: 'Projekt und Baustatus',
        component: ConstructionProjectStates,
        icon: 'mdi-format-list-numbered',
        meta: {
          visible: true,
          auth: [
            'camunda-admin',
            'LgSpotAccess',
            'LgSpotBau',
            'LgSpotBauLeitung',
            'LgSpotAuftragsmanagement',
            'LgSpotFieldservice',
            'LgSpotSecondLevel'
          ]
        }
      },
      {
        path: 'home-passed-list',
        name: 'Home-Passed Freigabe',
        component: HomePassedUnblocking,
        icon: 'mdi-format-list-bulleted-type',
        meta: {
          visible: true,
          auth: ['camunda-admin', 'LgSpotGnc', 'LgSpotBau', 'LgSpotBauLeitung']
        }
      },
      {
        path: 'saved-for-second-construction-phase-list',
        name: 'Für zweiten Bauabschnitt gespeichert',
        component: SavedForSecondConstructionPhaseUnblocking,
        icon: 'mdi-format-list-bulleted-type',
        meta: {
          visible: true,
          auth: ['camunda-admin', 'LgSpotGnc', 'LgSpotBau', 'LgSpotBauLeitung']
        }
      },
      {
        path: 'map-overview',
        name: 'Übersichtkarte',
        component: ConstructionProjectOverviewMap,
        icon: 'mdi-map-legend',
        meta: {
          visible: true,
          auth: [
            'camunda-admin',
            'LgSpotProjekte',
            'LgSpotVertrieb',
            'LgSpotControlling',
            'LgSpotRegionalLeitung'
          ]
        }
      }
    ]
  },
  {
    path: '/support-start',
    name: 'Start Support',
    component: SupportStart,
    icon: 'mdi-help-network-outline',
    meta: {
      visible: true,
      auth: [
        'camunda-admin',
        'LgSpotSecondLevel',
        'LgSpotSecondLevelLeitung',
        'LgSpotHotline',
        'LgSpotHotlineLeitung',
        'LgSpotAuftragserfassung',
        'LgSpotExternXpron',
        'LgSpotExternBauermedia'
      ]
    }
  },
  {
    path: '/statistik-dashboard',
    name: 'Reporting',
    component: StatistikDashboard,
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAdmin', 'LgSpotControlling']
    },
    icon: 'mdi-view-dashboard'
  },
  {
    path: '/permission-overview',
    name: 'Berechtigungsübersicht',
    component: RoutesPermissionOverview,
    icon: 'mdi-folder-lock-open',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAdmin']
    }
  },
  {
    path: '/two-factor-admin',
    name: 'Zweifaktor-Admin',
    component: TwoFactorAdmin,
    icon: 'mdi-qrcode-scan',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotAdmin']
    }
  },
  {
    path: '/radius-session-lock',
    name: 'RADIUS Session Lock',
    component: RadiusSessionLock,
    icon: 'mdi-format-list-checks',
    meta: {
      visible: true,
      auth: ['camunda-admin', 'LgSpotBackbone', 'LgSpotSecondLevelLeitung']
    }
  },

  // {
  //   path: '/statistics',
  //   name: 'Statistik',
  //   component: Statistics,
  //   public: true,
  //   icon: 'mdi-format-list-bulleted-type',
  //   children: [
  //     {
  //       name: 'Adressen',
  //       component: Addresses,
  //       path: ':addresses',
  //       public: true,
  //     }
  //   ]
  // },

  { path: '*', redirect: '/' }
];

const router = new Router({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  window.document.title =
    (to.meta && to.meta.title ? to.meta.title : to.name) +
    ` | ${process.env.VUE_APP_TITLE ?? 'Goetel SPOT'}`;
  next();
});

export default router;
