<template>
  <div
    class="notification"
    @mouseenter="$store.commit('cancelNotificationTimeout', id)"
    @mouseleave="$store.commit('restartNotificationTimeout', id)"
  >
    <v-alert
      :value="true"
      outlined
      :color="notificationColor"
    >
      <v-row align="center">
        <v-col class="grow">
          <div class="notification-content">
            <div class="notification-header">
              <span v-if="type === 'error'">Achtung!</span>
              <span v-else-if="type === 'success'">Erfolg!</span>
              <span v-else>Information!</span>
            </div>
            <div class="notification-body">
              <ul v-for="(error, index) in content" :key="index">
                <li>{{ error }}</li>
              </ul>
            </div>
          </div>
        </v-col>
        <v-col class="shrink button-group">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="notificationColor"
                v-bind="attrs"
                v-on="on"
                @click="copyContentToClipboard"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Inhalt in die Zwischenablage kopieren</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="notificationColor"
                v-bind="attrs"
                v-on="on"
                @click="$store.commit('dismissNotification', id)"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </template>
            <span>Schließen</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    content: {
      type: Array,
      required: true
    }
  },
  computed: {
    notificationColor() {
      let color;
      switch (this.type) {
        case 'error':
          color = 'var(--goe-color-error)';
          break;
        case 'success':
          color = 'green';
          break;
        case 'info':
          color = 'var(--goe-fontColor-highlightDark)';
          break;
        case 'warning':
          color = 'orange lighten-1';
          break;
      }
      return color;
    }
  },
  methods: {
    copyContentToClipboard: function () {
      const clipboardy = require('clipboardy');
      let copiedContent = '';
      for (const error of this.content) {
        copiedContent = `${copiedContent} ${error}\n`;
      }
      clipboardy.write(copiedContent);
    }
  }
};
</script>

<style scoped>
.button-group {
  min-width: max-content;
}
.notification {
  max-width: 100vw;
  height: auto;
  background: var(--goe-background-highlight);
  box-shadow: var(--goe-box-shadow);
}

.notification-content {
  padding: var(--goe-spacing-1);
}

.notification-body {
  font-size: var(--goe-fontSize-small);
}
</style>
