<template>
  <!-- Nothing to show -->
  <div />
</template>

<script>
import Authentication from './Authentication.js';

export default {
  name: 'AuthenticationWrapper',
  data: () => ({
    timer: null,
    defaultRefreshTime: process.env.VUE_APP_DEFAULT_REFRESH_TIME
  }),
  computed: {
    loginStatus() {
      return this.$store.getters.getLoginStatus;
    }
  },
  created: function () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'triggerLogout') {
        this.logout();
      }
      if (mutation.type === 'loginChange' && this.loginStatus === 'SUCCESS') {
        this.setRefreshTimer(this.defaultRefreshTime);
      }
    });
  },
  mounted: function () {
    this.refreshToken();
  },
  methods: {
    logout() {
      this.$store.commit('loginChange', 'NONE');
      clearTimeout(this.timer);
      this.$auth.logout();
      Authentication.logout();
    },
    refreshToken() {
      Authentication.refreshToken()
        .then((refreshInfo) => {
          this.$auth.user({
            userName: refreshInfo.userName,
            userRoles: refreshInfo.userRoles,
            twoFactorAuthenticated: refreshInfo.twoFactorAuthExempt
              ? true
              : refreshInfo.twoFactorAuthSetup
          });
          this.$auth.token('token', refreshInfo.token);
          this.$store.commit('setUsername', refreshInfo.userName);
          this.$store.commit('loginChange', 'REFRESHED');
          this.setRefreshTimer((refreshInfo.tokenTTL - 10) * 1000);
          const redirect = this.$auth.redirect();
          if (redirect) {
            this.$router.push(redirect.from.fullPath);
          }
        })
        .catch(() => {
          // Could not get new access token, log out instead
          this.handleRefreshFailure();
        });
    },
    setRefreshTimer(time) {
      if (time === undefined) {
        time = this.defaultRefreshTime;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(this.refreshToken, time);
    },
    handleRefreshFailure() {
      // If there was a previous authentication, trigger a logout. If not, nothing to do
      if (this.$auth.check()) {
        this.$store.commit('triggerLogout');
      }
    }
  }
};
</script>

<style scoped></style>
