<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="secondary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
        <template v-if="username != null && username != ''">
          {{ userstring !== null ? userstring : username }}
        </template>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img :src="`${publicPath}logo.svg`" />
          </v-list-item-avatar>

          <v-list-item-content v-if="username != null && username != ''">
            <v-list-item-title>
              {{ userstring !== null ? userstring : username }}
            </v-list-item-title>
            <v-list-item-subtitle>info@goetel.de</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="userGroups.includes('camunda-admin')">
          <v-list-item-content>
            <v-list-item-subtitle>
              Session gültig bis: {{ expirationDate }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Session läuft ab in:
              {{ minutes }}:{{ seconds }} min
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Aktuelle Idle-Zeit:
              {{ lastActionMinutes }}:{{ lastActionSeconds }} min
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="darkmode"></v-switch>
          </v-list-item-action>
          <v-list-item-title>Darkmode</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="text"
          @click="(menu = false), $store.commit('triggerLogout')"
        >
          <v-icon>mdi-account</v-icon>
          Abmelden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'AccountWidget',
  components: {},
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true
    },
    lastAction: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    userstring: null,
    publicPath: process.env.BASE_URL,
    menu: false,
    darkmode: false,
    countdown: undefined,
    currentDate: undefined,
    expirationDate: ''
  }),
  computed: {
    seconds() {
      return this.countdown % 60;
    },
    minutes() {
      return Math.floor(this.countdown / 60);
    },
    lastActionSeconds() {
      return Math.floor((this.currentDate - this.lastAction) / 1000) % 60;
    },
    lastActionMinutes() {
      return Math.floor((this.currentDate - this.lastAction) / 60000);
    },
    userGroups() {
      return this.$auth.user().userRoles;
    },
    username() {
      return this.$auth.user().userName;
    },
    countdownDate() {
      var date = new Date(1970, 0, 1);
      date.setSeconds(this.countdown);
      return date.toLocaleTimeString();
    }
  },
  watch: {
    countdown: function () {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
        }, 1000);
      }
    },
    currentDate: function () {
      if (this.userGroups.includes('camunda-admin')) {
        setTimeout(() => {
          this.currentDate = Date.now();
        }, 1000);
      }
    },
    username: function (value, _) {
      this.userstring = null;
      if (value !== null) {
        HTTP.get(`/engine/default/user/${value}/profile`)
          .then((resp) => {
            this.userstring = `${resp.data.firstName} ${resp.data.lastName}`;
          })
          .catch(() => {
            // Do nothing => Show userId
          });
      }
    },
    darkmode(newVal) {
      this.$vuetify.theme.dark = newVal;
    }
  },
  mounted: function () {
    const now = new Date();
    const offset = now.getTimezoneOffset() * -1;
    const tokenBase64 = this.$auth.token().split('.')[1];
    const tokenData = JSON.parse(atob(tokenBase64));
    const expirationTime = tokenData.exp;
    var date = new Date(1970, 0, 1);
    date.setSeconds(expirationTime + offset * 60);
    this.expirationDate = date.toLocaleTimeString();
    this.countdown = Math.round((date.getTime() - now.getTime()) / 1000);
    this.currentDate = Date.now();
  }
};
</script>
<style scoped lang="scss"></style>
