import { print } from 'graphql/language/printer';

const MONDAY_API =
  process.env.VUE_APP_MONDAY_API || 'https://api.monday.com/v2';
const MONDAY_TOKEN =
  process.env.VUE_APP_MONDAY_TOKEN ||
  'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE3MzU2NTg0NCwidWlkIjozMjgwMDUxMiwiaWFkIjoiMjAyMi0wOC0wM1QwODo0ODo1NC4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTAyOTQ3MzYsInJnbiI6ImV1YzEifQ.k5oRgb6xqsefIxnfHdleGxoqPO5McghtSLIc17DAqGc';

function apiRequest(body) {
  return fetch(MONDAY_API, {
    method: 'POST',
    body: JSON.stringify(body || {}),
    headers: {
      Authorization: MONDAY_TOKEN,
      'Content-Type': 'application/json'
    }
  });
}

async function mondayApiRequest(query, variables) {
  if (typeof query === 'object') query = print(query);

  const response = await apiRequest({
    query,
    variables
  });

  const responseStatusCode = response.status;
  const responseContentType = response.headers.get('content-type');

  if (
    !responseContentType ||
    !responseContentType.includes('application/json')
  ) {
    if (responseStatusCode !== 200) {
      const responseText = await response.text();
      throw new Error(responseText);
    }
  }

  return await response.json();
}

export async function convertMondayItems(items) {
  if (!Array.isArray(items)) {
    return Promise.reject(new Error('Items has to be an array'));
  }
  if (items.length <= 0) return Promise.resolve(new Map());

  const convertedItems = new Map();

  for (const item of items) {
    if ('id' in item === false || 'column_values' in item === false) continue;

    const convertedItem = {};

    for (const value of item.column_values) {
      if ('text' in value === false || 'title' in value === false) continue;

      convertedItem[camelize(value.title)] = value.text;
    }

    convertedItems.set(parseInt(item.id), convertedItem);
  }

  return convertedItems;
}

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export default mondayApiRequest;
