<template>
  <div id="app">
    <v-app class="app-wrapper">
      <template v-if="isAuthenticated">
        <v-app-bar
          app
          color="primary"
          dark
          elevation="0"
        >
          <v-app-bar-nav-icon @click.stop="sidebarMenu = !sidebarMenu"></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <!-- Not used yet
          <v-autocomplete
            class="app-search"
            placeholder="Suche"
            append-icon="mdi-magnify"
          >
          </v-autocomplete>
          <v-spacer></v-spacer>-->
          <tool-widget
            v-if="allowedTools && allowedTools.length > 0"
            :tools="allowedTools"
          ></tool-widget>
          <account-widget
            :isAuthenticated="isAuthenticated"
            :lastAction="lastAction"
          />
        </v-app-bar>
        <v-navigation-drawer
          v-model="sidebarMenu"
          app
          floating
          :permanent="sidebarMenu"
          :mini-variant.sync="mini"
          :width="265"
          elevation="1"
          class="sidebar-drawer light-shadow"
        >
          <v-list
            dense
            color="primary"
            dark
          >
            <v-list-item>
              <v-list-item-action>
                <v-icon
                  :class="{ rotate: toggleMini }"
                  @click.stop="toggleMini = !toggleMini"
                >
                  mdi-chevron-left
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <spot-logo
                    :showSubtitle="true"
                    size="m"
                  />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <template v-for="(route, index) in $router.options.routes">
              <v-list-item
                v-if="
                  !route.children &&
                    !!route.meta &&
                    route.meta.visible &&
                    (!route.meta.auth ||
                      (typeof route.meta.auth == 'boolean'
                        ? $auth.check()
                        : $auth.check(route.meta.auth)))
                "
                :key="index"
                link
                :to="route.path"
              >
                <v-list-item-icon>
                  <v-icon color="primary">
                    {{ route.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    {{ route.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- childmenu -->
              <v-list-group
                v-if="
                  route.children &&
                    !!route.meta &&
                    route.meta.visible &&
                    (typeof route.meta.auth == 'boolean'
                      ? $auth.check()
                      : $auth.check(route.meta.auth))
                "
                :key="index"
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon color="primary">
                      {{ route.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="primary--text">
                    {{ route.name }}
                  </v-list-item-title>
                </template>
                <v-list-item
                  v-if="route.children"
                  :key="index + 'sub'"
                  class="submenu"
                  dense
                >
                  <v-list-item-content>
                    <v-list>
                      <template v-for="(child, index2) in route.children">
                        <v-list-item
                          v-if="child.meta && child.meta.visible"
                          :key="index2"
                          link
                          :to="route.path + '/' + child.path"
                        >
                          <v-list-item-icon>
                            <v-icon v-if="child.icon">
                              {{ child.icon }}
                            </v-icon>
                            <v-icon v-else>
                              mdi-chevron-right
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ child.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <!-- childmenu-end -->
            </template>
          </v-list>
        </v-navigation-drawer>
        <v-main>
          <v-container
            class="px-4 py-0 fill-height"
            fluid
          >
            <v-row class="fill-height">
              <v-col>
                <transition name="fade">
                  <router-view></router-view>
                </transition>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </template>
      <template
        v-else
        class="login-wrapper"
      >
        <Login />
      </template>
      <GoetelFooter :isAuthenticated="isAuthenticated" />
      <NotificationContainer />
      <AuthenticationWrapper />
    </v-app>
  </div>
</template>

<script>
import Login from '@/views/Login.vue';
import NotificationContainer from './elements/NotificationContainer.vue';
import GoetelFooter from './elements/GoetelFooter.vue';
import AuthenticationWrapper from './AuthenticationWrapper.vue';
import SpotLogo from './elements/spotLogo.vue';
import AccountWidget from './elements/accountWidget.vue';

import Logger from './Logger.js';
import Tools from './tools.js';

const TITLE = process.env.VUE_APP_TITLE;

export default {
  name: 'App',
  components: {
    AuthenticationWrapper,
    Login,
    GoetelFooter,
    NotificationContainer,
    SpotLogo,
    AccountWidget,
    ToolWidget: () => import('./elements/toolWidget.vue')
  },
  data: () => ({
    lastAction: Date.now(),
    activityCheckTimeout: null,
    sidebarMenu: true,
    toggleMini: false
  }),
  computed: {
    isAuthenticated() {
      return (
        this.$auth.check() &&
        this.user &&
        Object.keys(this.user).length !== 0 &&
        this.user.twoFactorAuthenticated
      );
    },
    user() {
      return this.$auth.user();
    },
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    year() {
      return new Date().getFullYear();
    },
    allowedTools() {
      return Tools.filter((tool) => tool.auth && this.$auth.check(tool.auth));
    }
  },
  created: function () {
    // Change the title of the website if something is entered in the env
    if (TITLE != null) document.title = TITLE;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'triggerLogout') {
        this.logout();
      }
    });
  },
  mounted: function () {
    this.setActivityCheckTimeout();
    document.addEventListener('keydown', this.handleActivity);
    document.addEventListener('mousedown', this.handleActivity);
  },
  methods: {
    logout() {
      this.$store.commit('removeUsername');
    },
    handleActivity(event) {
      this.lastAction = Date.now();
    },
    setActivityCheckTimeout() {
      if (this.activityCheckTimeout !== null) {
        clearTimeout(this.activityCheckTimeout);
      }
      this.activityCheckTimeout = setTimeout(this.checkActivity, 5000);
    },
    checkActivity() {
      const idleTime = Date.now() - this.lastAction;
      Logger.log(
        'App',
        `idle time maximum (${process.env.VUE_APP_MAX_IDLE_TIME}ms)`
      );
      if (
        idleTime > process.env.VUE_APP_MAX_IDLE_TIME &&
        this.isAuthenticated
      ) {
        // idle time exceeds maximum, trigger logout
        Logger.log(
          'App',
          `idle time exceeds maximum (${process.env.VUE_APP_MAX_IDLE_TIME}ms), trigger logout. Current idle time: ${idleTime}ms`
        );
        this.$store.commit('triggerLogout');
      }
      this.setActivityCheckTimeout();
    }
  }
};
</script>
<style scoped lang="scss">
.app-search {
  margin-top: 20px !important;
}
.app-search::v-deep input {
  background: transparent;
  margin-top: 10px;
}
.v-app-bar .v-input--switch {
  padding: 20px 20px 0 0;
}
.v-navigation-drawer .rotate {
  transform: rotate(180deg);
}
.v-main {
  max-width: calc(100% - 20px);
}
.submenu .list-item {
  color: #aaa;
}
.submenu .v-list-item__content {
  padding: 0;
}
.submenu .v-list-item--active::before {
  opacity: 0;
}
.submenu .v-list-item--active {
  color: #209bd8;
}
.btn-start-support {
  color: white;
  float: right;
}
</style>
<style>
@import '../assets/appstyle.css';
@import '../assets/vue-multiselect.min.css';
@import '../assets/materialdesignicons.min.css';
@import '../assets/spot.css';
</style>
