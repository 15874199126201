import Vue from 'vue';
import App from './main/App';
import router from './main/routes';
import store from './main/store';

import VueEllipseProgress from 'vue-ellipse-progress';
import ToggleButton from 'vue-js-toggle-button';
import Multiselect from 'vue-multiselect';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'typeface-roboto/index.css';

import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';

import { HTTP } from './main/httpClient.js';

import VueCompositionApi from '@vue/composition-api';
import vuetify from './main/vuetify';
// import vuetify from 'vuetify/lib/framework'

import VueApexCharts from 'vue-apexcharts';
import Vuetify from 'vuetify';
Vue.use(ToggleButton);
Vue.use(Vuetify);
Vue.use(VueEllipseProgress);
Vue.use(Multiselect);
Vue.use(VueCompositionApi);
Vue.use(VueApexCharts);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
});

Vue.use(auth, {
  plugins: {
    http: HTTP, // Axios
    router: router
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: 'userRoles',
    tokenDefaultKey: 'token',
    notFoundRedirect: '/tasks',
    forbiddenRedirect: '/tasks',
    fetchData: { enabled: false },
    refreshData: { enabled: false },
    tokenExpired: () => {
      return false;
    }
  }
});

Vue.component('multiselect', Multiselect);
Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = true;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
