const currentLogLevel = modeToLogLevel(process.env.NODE_ENV);

function createMessage(key, message) {
  return `### ${key.toString().toUpperCase()}: ${message.toString()}`;
}

function modeToLogLevel(level) {
  let logLevel = -1;

  switch (level) {
    case 'development':
      logLevel = 0;
  }

  return logLevel;
}

function error(key, message, payload = ' ', level = 'development') {
  if (currentLogLevel >= modeToLogLevel(level)) {
    console.log(createMessage(key, message), payload);
  }
}

function log(key, message, payload = ' ', level = 'development') {
  if (currentLogLevel >= modeToLogLevel(level)) {
    console.log(createMessage(key, message), payload);
  }
}

function warn(key, message, payload = ' ', level = 'development') {
  if (currentLogLevel >= modeToLogLevel(level)) {
    console.log(createMessage(key, message), payload);
  }
}

export default {
  error,
  log,
  warn
};
