<template>
  <div class="notifications-list">
    <template v-for="notification in notifications">
      <Notification
        :id="notification.id"
        :key="notification.id"
        :type="notification.type"
        :content="notification.content"
      />
    </template>
  </div>
</template>

<script>
import Notification from './Notification.vue';

export default {
  name: 'NotificationContainer',
  components: {
    Notification
  },
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    }
  }
};
</script>

<style scoped>
.notifications-list {
  bottom: 0;
  left: auto;
  position: fixed;
  right: var(--goe-spacing-0);
  top: auto;
  width: max-content;
  margin-bottom: var(--goe-spacing-4);
  max-height: calc(100vh - var(--goe-spacing-5));
  overflow: auto;
  z-index: 100000;
}
</style>
