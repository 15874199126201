import { HTTP } from './httpClient'

const OBJECT_TYPE = {
  CUSTOMER: 1,
  CONTRACT: 2,
  USER_TASK: 3
}

const getComments = (objectType, objectId) => {
  return HTTP.get(`/comments?objectType=${objectType}&objectId=${objectId}`)
    .then(({ data }) => {
      return data;
    });
}

const getCommentCount = (objectType, objectId) => {
  return HTTP.get(`/comments/count?objectType=${objectType}&objectId=${objectId}`)
    .then(({ data }) => {
      return parseInt(data);
    });
}

const addComment = (objectType, objectId, content) => {
  return HTTP.post('/comments', {
    objectType,
    objectId,
    content
  })
    .then(({ data }) => {
      return data;
    })
}

export {
  addComment,
  getComments,
  getCommentCount,
  OBJECT_TYPE
}
