<template>
  <div>
    <h2 v-if="showSubtitle" class="spot-logo" :class="size" style="margin-bottom: -5px;">
      <strong>
        <span>
          <img v-if="login" :src="`${publicPath}spot-logo-orange.svg`" height="120px" alt="SPOT" style="margin: -20px 0;" />
          <img v-else :src="`${publicPath}spot-logo-white.svg`" height="33px" alt="SPOT" style="margin-top: -6px;" />
          <template v-if="showVersion"> | Version: {{ buildNumber }}</template>
        </span>
      </strong>
      <small v-if="showSubtitle" style="margin-top: -10px;">Service Provider Operating Tasks</small>
    </h2>
    <h2 v-else-if="!showSubtitle" class="spot-logo" :class="size">
      <strong><i class="mdi mdi-spotlight-beam"></i>
        <span>
          SPOT
          <template v-if="showVersion"> | Version: {{ buildNumber }}</template>
        </span>
      </strong>
    </h2>
  </div>
</template>
<script>
export default {
  name: 'SpotLogo',
  components: {},
  props: {
    showSubtitle: {
      type: Boolean,
      required: false,
      default: false
    },
    showVersion: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'm'
    },
    login: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    buildNumber: process.env.VUE_APP_BUILD_NUMBER,
    publicPath: process.env.BASE_URL
  }),
  methods: {}
};
</script>
<style scoped lang="scss">
.spot-logo {
  strong,
  small {
    width: 100%;
    display: block;
  }
  span {
    font-family: 'isonorm';
    letter-spacing: 3px;
  }
  small {
    color: #ddd;
    font-size: 8px;
  }
}
h2.l span,
h2.l i {
  font-size: 36px;
}
h2.m span h2.m i {
  font-size: 24px;
}
h2.s span,
h2.s i {
  font-size: 16px;
}
</style>
