var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{staticClass:"app-wrapper"},[(_vm.isAuthenticated)?[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","elevation":"0"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.sidebarMenu = !_vm.sidebarMenu}}}),_c('v-spacer'),(_vm.allowedTools && _vm.allowedTools.length > 0)?_c('tool-widget',{attrs:{"tools":_vm.allowedTools}}):_vm._e(),_c('account-widget',{attrs:{"isAuthenticated":_vm.isAuthenticated,"lastAction":_vm.lastAction}})],1),_c('v-navigation-drawer',{staticClass:"sidebar-drawer light-shadow",attrs:{"app":"","floating":"","permanent":_vm.sidebarMenu,"mini-variant":_vm.mini,"width":265,"elevation":"1"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.sidebarMenu),callback:function ($$v) {_vm.sidebarMenu=$$v},expression:"sidebarMenu"}},[_c('v-list',{attrs:{"dense":"","color":"primary","dark":""}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',{class:{ rotate: _vm.toggleMini },on:{"click":function($event){$event.stopPropagation();_vm.toggleMini = !_vm.toggleMini}}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('spot-logo',{attrs:{"showSubtitle":true,"size":"m"}})],1)],1)],1)],1),_c('v-list',[_vm._l((_vm.$router.options.routes),function(route,index){return [(
                !route.children &&
                  !!route.meta &&
                  route.meta.visible &&
                  (!route.meta.auth ||
                    (typeof route.meta.auth == 'boolean'
                      ? _vm.$auth.check()
                      : _vm.$auth.check(route.meta.auth)))
              )?_c('v-list-item',{key:index,attrs:{"link":"","to":route.path}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(route.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(route.name)+" ")])],1)],1):_vm._e(),(
                route.children &&
                  !!route.meta &&
                  route.meta.visible &&
                  (typeof route.meta.auth == 'boolean'
                    ? _vm.$auth.check()
                    : _vm.$auth.check(route.meta.auth))
              )?_c('v-list-group',{key:index,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(route.icon)+" ")])],1),_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(route.name)+" ")])]},proxy:true}],null,true)},[(route.children)?_c('v-list-item',{key:index + 'sub',staticClass:"submenu",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list',[_vm._l((route.children),function(child,index2){return [(child.meta && child.meta.visible)?_c('v-list-item',{key:index2,attrs:{"link":"","to":route.path + '/' + child.path}},[_c('v-list-item-icon',[(child.icon)?_c('v-icon',[_vm._v(" "+_vm._s(child.icon)+" ")]):_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(child.name)+" ")])],1)],1):_vm._e()]})],2)],1)],1):_vm._e()],1):_vm._e()]})],2)],1),_c('v-main',[_c('v-container',{staticClass:"px-4 py-0 fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1)],1)],1)],1)],1)]:[_c('Login')],_c('GoetelFooter',{attrs:{"isAuthenticated":_vm.isAuthenticated}}),_c('NotificationContainer'),_c('AuthenticationWrapper')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }