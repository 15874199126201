import { HTTP } from '@/main/httpClient.js';

function camundaStoreLocalMemoVariable(taskId, memo) {
  return HTTP.post(`/task/${taskId}/localVariables`, {
    modifications: {
      memo: {
        value: memo,
        type: 'String'
      }
    }
  });
}

export { camundaStoreLocalMemoVariable };
