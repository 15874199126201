import axios from 'axios';

const BASE_API_PROTOCOL = process.env.VUE_APP_BASE_API_PROTOCOL;
const BASE_API_PATH = process.env.VUE_APP_BASE_API_PATH;
const BASE_APPLICATION_PATH = process.env.VUE_APP_BASE_APPLICATION_PATH;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
// https://stackoverflow.com/questions/44053577/looping-api-calls-with-axios-and-promises
// expects fn() to throw if it failed
// if it runs out of retries, poll() will resolve to an rejected promise, containing the latest error
export const POLL = (fn, retries = Infinity, timeoutBetweenAttempts = 1000) => {
  return Promise.resolve()
    .then(fn)
    .catch(function retry(err) {
      if (retries-- > 0) {
        return delay(timeoutBetweenAttempts).then(fn).catch(retry);
      }
      throw err;
    });
};
export const HTTP = axios.create({
  baseURL: encodeURI(
    `${BASE_API_PROTOCOL}://${BASE_API_PATH}/${BASE_APPLICATION_PATH}`
  )
});

export default { HTTP, POLL };
