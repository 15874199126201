import { HTTP } from '@/main/httpClient.js';
import Logger from '@/main/Logger.js';

class Authentication {
  authToken = null;
  jwtTimer = null;

  getAuthenticationHeader() {
    let header = null;
    if (this.username !== null && this.password !== null) {
      header = `Bearer ${this.authToken}`;
    }
    return header;
  }

  login(userName, password) {
    const loginHeader = `Basic ${Buffer.from(`${userName}:${password}`, 'utf-8').toString('base64')}`;
    const config = {
      headers: {
        Authorization: loginHeader,
        withCredentials: true
      }
    };
    return HTTP.post('/authentication/login', {}, config).then((response) => {
      authentication.authToken = response.data.jwt;
      return Promise.resolve({
        userName: response.data.userName,
        userRoles: response.data.userGroups,
        token: response.data.jwt,
        tokenTTL: response.data.jwtTTL,
        twoFactorAuthSetup: response.data.twoFactorAuthSetup,
        twoFactorAuthExempt: response.data.twoFactorAuthExempt
      });
    });
  }

  refreshToken() {
    return HTTP.post(
      '/authentication/refresh',
      {},
      { withCredentials: true }
    ).then((response) => {
      authentication.authToken = response.data.jwt;
      return Promise.resolve({
        userName: response.data.userName,
        userRoles: response.data.userGroups,
        token: response.data.jwt,
        tokenTTL: response.data.jwtTTL,
        twoFactorAuthSetup: response.data.twoFactorAuthSetup,
        twoFactorAuthExempt: response.data.twoFactorAuthExempt
      });
    });
  }

  logout() {
    Logger.log('HTTP', 'Logout triggered!');
    HTTP.post('/authentication/logout', {}, { withCredentials: true })
      .then((response) => {
        authentication.authToken = null;
      })
      .catch(() => {
        authentication.authToken = null;
      });
  }

  getQRImage() {
    Logger.log('HTTP', 'QR Image fetch triggered');
    const config = {
      headers: {
        Authorization: this.getAuthenticationHeader()
      }
    };
    return HTTP.post('authentication/twofactor/setup', {}, config).then(
      (response) => {
        return Promise.resolve({
          qrImageContent: response.data.qrImageContent
        });
      }
    );
  }

  validateTwoFactorAuthentication(code) {
    Logger.log('HTTP', '2FA code validation triggered');
    const body = {
      code: code
    };
    const config = {
      headers: {
        Authorization: this.getAuthenticationHeader()
      }
    };
    return HTTP.post('authentication/twofactor/validate', body, config).then(
      (response) => {
        return Promise.resolve({
          isValid: response.data.valid,
          token: response.data.token
        });
      }
    );
  }
}

const authentication = new Authentication();

export default authentication;
