export default class NotificationObject {
  constructor(type, content) {
    this.type = type;
    if (typeof content === 'string') {
      this.content = [content];
    } else {
      this.content = content;
    }
    this.id = `notification_${type}_${new Date().getTime()}_${Math.random()}`;
  }
}
