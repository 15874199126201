<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <div class="tfa-container">
      <v-btn
        class="float-right"
        small
        plain
        @click="close"
      >
        <v-icon small>
          mdi-window-close
        </v-icon>
      </v-btn>
      <h2>Zwei Faktor Authentifizierung</h2>
      <div v-if="!authResponse.twoFactorAuthSetup" class="description">
        Scannen Sie den folgenden QR-Code mit der
        Zwei-Faktor-Authentifizierungs-App.
      </div>
      <div v-if="!authResponse.twoFactorAuthSetup" class="qrCodeContainer">
        <img :src="qrImageContent" />
      </div>
      <div class="description">
        Geben Sie den Code aus der Zwei-Faktor-Authentifizierungs-App ein.
      </div>
      <v-text-field
        v-model="code"
        name="otp_input"
        autofocus
        flat
        label="Code"
        maxlength="6"
        @keydown.enter="validateToken"
      />
      <v-btn
        color="primary"
        elevation="2"
        class="align-center"
        :disabled="!code"
        @click="validateToken"
      >
        Validate
      </v-btn>
    </div>
  </v-dialog>
</template>
<script>
import Authentication from '@/main/Authentication.js';
import Logger from '@/main/Logger.js';
import NotificationObject from '@/main/NotificationObject.js';

const LOGGER_KEY = 'TWOFACTORAUTH';

export default {
  name: 'TwoFactorAuthenticationPopup',
  props: {
    authResponse: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    qrImageContent: '',
    code: ''
  }),
  watch: {
    authResponse: function () {
      this.setupAuthentication(this.authResponse.twoFactorAuthSetup);
    }
  },
  methods: {
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    validateToken() {
      Authentication.validateTwoFactorAuthentication(this.code)
        .then((resp) => {
          if (resp.isValid === true) {
            this.dialog = false;
            // Set the user session values
            this.$auth.user({
              userName: this.authResponse.userName,
              userRoles: this.authResponse.userRoles,
              twoFactorAuthenticated: resp.isValid
            });
            // Set the newly generated token after 2FA succeeds
            this.$auth.token('token', resp.token, true);
            this.$store.commit('setUsername', this.authResponse.userName);
            this.$store.commit('loginChange', 'SUCCESS');
          } else {
            this.registerLoginFailure();
          }
        })
        .catch((err) => {
          Logger.log(LOGGER_KEY, 'Token validation failed.');
          Logger.log(LOGGER_KEY, err);
          this.registerLoginFailure();
        });
    },
    registerLoginFailure() {
      this.$store.commit('loginChange', 'FAILED');
      this.$store.commit(
        'addNotification',
        new NotificationObject('error', 'Die Anmeldung war nicht erfolgreich!')
      );
    },
    setupAuthentication(isTwoFactorAuthSetup) {
      if (isTwoFactorAuthSetup === false) {
        Authentication.getQRImage()
          .then((resp) => {
            this.qrImageContent = resp.qrImageContent;
          })
          .catch((err) => {
            Logger.log(LOGGER_KEY, 'QR image fetch failed');
            Logger.log(LOGGER_KEY, err);
            this.registerLoginFailure();
          });
      }
    }
  }
};
</script>
<style scoped>
.tfa-container {
  padding: var(--goe-spacing-2);
  background: var(--goe-background-light);
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  margin-top: var(--goe-spacing-0_5);
  text-align: center;
  font-size: var(--goe-fontSize-normal);
  color: var(--goe-fontColor-highlight);
  font-weight: var(--goe-fontWeight-bold);
}

.description {
  font-size: var(--goe-fontSize-small);
  color: var(--goe-fontColor-light);
  margin-bottom: var(--goe-spacing-0);
  margin-top: var(--goe-spacing-2);
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
