<template>
  <v-footer class="py-2 mt-2">
    <div style="width: 151px; height: 50px;"></div>
    <v-spacer></v-spacer>
    <spot-logo :showVersion="true" size="s" />
    <v-spacer></v-spacer>
    <a href="https://www.goetel.de/"><img :src="`${publicPath}logo.svg`" alt="GOETEL" /></a>
  </v-footer>
</template>

<script>
import spotLogo from './spotLogo.vue';

export default {
  name: 'GoetelFooter',
  components: { spotLogo },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    buildNumber: process.env.VUE_APP_BUILD_NUMBER,
    publicPath: process.env.BASE_URL,
    userstring: null
  }),
  computed: {
    username() {
      return this.$auth.user().userName;
    }
  }
};
</script>

<style scoped>
.footer {
  height: var(--goe-spacing-3);
  padding: var(--goe-spacing-0_3);
  background: var(--goe-background-darker);
  display: grid;
  grid-template-columns: auto auto auto;
  color: var(--goe-fontColor-white);
  font-size: var(--goe-fontSize-small);
  text-align: center;
  z-index: 10000000;
}

.footer > div {
  margin: auto var(--goe-spacing-1);
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

img {
  height: var(--goe-spacing-5);
  margin-left: var(--goe-spacing-2);
  margin-top: 10px;
}
a img {
  padding-right: 15px;
}
</style>
