import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// Vue.config.devtools = true;

const NOTIFICATIONS_DISPLAYED_DURATION_GENERAL = 5000;
const NOTIFICATIONS_DISPLAYED_DURATION_ERROR = 10000;

const state = {
  notifications: [],
  currentUsername: '',
  loginStatus: 'NONE' // Possible states: NONE, SUCCESS, FAILED, REFRESHED
};

const getters = {
  getNotifications(state) {
    return state.notifications;
  },
  getUsername(state) {
    return state.currentUsername;
  },
  getLoginStatus(state) {
    return state.loginStatus;
  }
};

const mutations = {
  addNotification(state, notification) {
    const THIS = this;
    state.notifications.push(notification);
    const timeout = setTimeout(
      function () {
        THIS.commit('dismissNotification', notification.id);
      },
      notification.type === 'error'
        ? NOTIFICATIONS_DISPLAYED_DURATION_ERROR
        : NOTIFICATIONS_DISPLAYED_DURATION_GENERAL
    );
    notification.timeout = timeout;
  },
  dismissNotification(state, notificationId) {
    state.notifications = state.notifications.filter(
      (item) => item.id !== notificationId
    );
  },
  cancelNotificationTimeout(state, notificationId) {
    const notification = state.notifications.find(
      (notification) => notification.id === notificationId
    );
    clearTimeout(notification.timeout);
    notification.timeout = 0;
  },
  restartNotificationTimeout(state, notificationId) {
    const THIS = this;
    const notification = state.notifications.find(
      (notification) => notification.id === notificationId
    );
    notification.timeout = setTimeout(
      function () {
        THIS.commit('dismissNotification', notification.id);
      },
      notification.type === 'error'
        ? NOTIFICATIONS_DISPLAYED_DURATION_ERROR
        : NOTIFICATIONS_DISPLAYED_DURATION_GENERAL
    );
  },
  loginChange(state, loginStatus) {
    state.loginStatus = loginStatus;
  },
  triggerLogout() {}, // No data needed
  triggerTasksRefresh() {}, // No data needed
  setUsername(state, username) {
    state.currentUsername = username;
  },
  removeUsername(state) {
    state.currentUsername = null;
  },

  // provisioning
  setContractId(state, val) {
    state.contractState.contractId = val;
  }
};

const actions = {
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});
