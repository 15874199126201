export default [
  {
    id: 'contract-phase-in',
    name: 'Verträge einphasen',
    component: () => import('@/components/tools/ContractPhaseIn.vue'),
    auth: ['camunda-admin', 'LgSpotAuftragsmanagement', 'LgSpotHotline', 'LgSpotVertrieb', 'LgSpotAccess', 'LgSpotSecondLevel'],
    icon: 'mdi-file-edit',
    width: '750px',
    colorScheme: 'primary'
  }
];
